import script from "./Settings.vue?vue&type=script&setup=true&lang=js"
export * from "./Settings.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QList from 'quasar/src/components/item/QList.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QToggle from 'quasar/src/components/toggle/QToggle.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QSlider from 'quasar/src/components/slider/QSlider.js';import Ripple from 'quasar/src/directives/Ripple.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QList,QItemLabel,QItem,QItemSection,QSeparator,QCheckbox,QToggle,QIcon,QSlider});qInstall(script, 'directives', {Ripple});
